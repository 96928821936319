<template>
    <div class="contents_wait">

        <header class="header">
            <h1><a href=""><img src="@/assets/img/common/logo.svg" alt="MOOX"></a></h1>
        </header>

        <div class="container_top02">

            <section class="section_wait">
                <div class="cmInner">

                    <div class="mainTitle"><img src="@/assets/img/route1/1-1/main_logo.svg" alt="MOOX RIDE Navi" />
                    </div>
                    <p class="waitText01">処理中です。<br>しばらくお待ちください。</p>
                    <p class="waitText01">Processing is in progress.<br>Please wait a moment.</p>
                </div>
            </section>

        </div>

    </div>
</template>
<style scoped>
@import "../css/style.css";
</style>
<script>
// import MooxApi from '../MooxApi';

export default {
    name: "Home",
    components: {

    },
    data() {
        return {
            seatId: null,
            vehicleId: null,
            token: null,
            // triggers: [],
            // responseString: "",
        };
    },
    async mounted() {
        console.log("moutend Home");

        // MooxApi.StartUpdate();
    },
    methods: {
    }
};
</script>