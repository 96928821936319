<template>
  <div id="app">
    <router-view :isOpenChoiceShort="isOpenChoiceShort" @on-message="onChildMessage"/>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import MooxApi from './MooxApi'
import VueCookies from 'vue-cookies'
// import VueQrcode from '@chenfengyuan/vue-qrcode';
Vue.use(VueCookies, { expires: '60 * 60 * 6'})

// 参考
// ブラウザバックの無効化
// https://zukucode.com/2017/09/javascript-browser-back.html
// https://zenn.dev/abcd/articles/97ae850552e9a5
history.pushState(null, "", null);
history.replaceState(null, "", null);

window.addEventListener("popstate", function () {
  history.pushState(null, "", null);
  history.replaceState(null, "", null);
});

let isPreventUpdate = true;
let parentApp = null;

export default {
  name: "App",
  // props: ['propSeatId','propVehicleId','propToken','propUserId', 'propSecret'],
  // components: { VueQrcode },
  data() {
    return {
      // pageListは発火時間が遅い順にソートされていることを前提とする
      pageList: [
        { id:16, key:"ChoiceSample", delaySec: 0, pageName: "Choice" },
        { id:17, key:"PageSample", delaySec: 0, pageName: "Page1" },
        { id:18, key:"RedirectTest", delaySec: 0, pageName: "Redirect", url: "https://www.google.co.jp/", mode:"Redirect" },
        { id:19, key:"SeatInfomation",delaySec: 0, pageName: "SeatInfomation"},
        { id:22, key:"Test 03", delaySec: 20, pageName: "ThanksShort" },
        { id:20, key:"Test 03", delaySec: 10, pageName: "AbstructLong" },
        { id:21, key:"Test 03", delaySec: 0, pageName: "FodShort" },
        { id:30, key:"CentrairExpo2024 CentrairToExpo 1-2", delaySec: 0, pageName: "Route1_2" },
        { id:31, key:"CentrairExpo2024 CentrairToExpo 1-3", delaySec: 0, pageName: "Route1_3" },
        { id:32, key:"CentrairExpo2024 CentrairToExpo 1-4", delaySec: 0, pageName: "Route1_4" },
        { id:33, key:"CentrairExpo2024 CentrairToExpo 1-5", delaySec: 0, pageName: "Route1_5" },
        { id:34, key:"CentrairExpo2024 CentrairToExpo 1-6", delaySec: 0, pageName: "Route1_6" },
        { id:35, key:"CentrairExpo2024 CentrairToExpo 1-7", delaySec: 0, pageName: "Route1_7" },
        { id:36, key:"CentrairExpo2024 CentrairToExpo 1-8", delaySec: 0, pageName: "Route1_8" },
        { id:37, key:"CentrairExpo2024 CentrairToExpo 1-9", delaySec: 0, pageName: "Route1_9" },
        { id:38, key:"CentrairExpo2024 CentrairToExpo 2-2", delaySec: 0, pageName: "Route2_2" },
        { id:39, key:"CentrairExpo2024 CentrairToExpo 2-3", delaySec: 0, pageName: "Route2_3" },
        { id:40, key:"CentrairExpo2024 CentrairToExpo 2-4", delaySec: 0, pageName: "Route2_4" },
        { id:41, key:"CentrairExpo2024 CentrairToExpo 2-5", delaySec: 0, pageName: "Route2_5" },
        { id:42, key:"CentrairExpo2024 CentrairToExpo 2-6", delaySec: 0, pageName: "Route2_6" },
        { id:43, key:"CentrairExpo2024 CentrairToExpo 2-7", delaySec: 0, pageName: "Route2_7" },
        { id:44, key:"CentrairExpo2024 CentrairToExpo 2-8", delaySec: 0, pageName: "Route2_8" },

        // 上からメッセージをチェックする関係で同じ発報メッセージでdelaySecが下よりも小さいと小さい値の方のページが表示される(delaySecの大きい下のページが表示されない)ので逆順にしている
        { id:66, key:"CentrairExpo2024 CentrairToExpo 11", delaySec: 0, pageName: "Route2_8" },
        { id:65, key:"CentrairExpo2024 CentrairToExpo 10", delaySec: 30, pageName: "Route2_7" },
        { id:64, key:"CentrairExpo2024 CentrairToExpo 10", delaySec: 0, pageName: "Route2_6" },
        { id:63, key:"CentrairExpo2024 CentrairToExpo 09", delaySec: 0, pageName: "Route2_5" },
        { id:62, key:"CentrairExpo2024 CentrairToExpo 08", delaySec: 0, pageName: "Route2_5" },
        { id:61, key:"CentrairExpo2024 CentrairToExpo 07", delaySec: 60, pageName: "Route2_4" },
        { id:60, key:"CentrairExpo2024 CentrairToExpo 07", delaySec: 0, pageName: "Route2_3" },
        { id:59, key:"CentrairExpo2024 CentrairToExpo 06", delaySec: 0, pageName: "Route2_2" },
        { id:58, key:"CentrairExpo2024 CentrairToExpo 05", delaySec: 0, pageName: "Route1_9" },
        { id:57, key:"CentrairExpo2024 CentrairToExpo 04", delaySec: 142, pageName: "Route1_8" },
        { id:56, key:"CentrairExpo2024 CentrairToExpo 04", delaySec: 112, pageName: "Route1_7" },
        { id:55, key:"CentrairExpo2024 CentrairToExpo 04", delaySec: 0, pageName: "Route1_6" },
        { id:54, key:"CentrairExpo2024 CentrairToExpo 03", delaySec: 55, pageName: "Route1_5" },
        { id:53, key:"CentrairExpo2024 CentrairToExpo 03", delaySec: 0, pageName: "Route1_4" },
        { id:52, key:"CentrairExpo2024 CentrairToExpo 02", delaySec: 0, pageName: "Route1_3" },
        { id:51, key:"CentrairExpo2024 CentrairToExpo 01", delaySec: 0, pageName: "Route1_2" },

      ],
      routeList: {
        // "80dbd148-b1a1-fad1-6906-cec0cac08d83":{departureName:"EXPO", destinationName: "セントレア", routeName: "toCentrair", routeId: "T0003_M0010_R0002", defaultPage: { id:100, delaySec: 0, pageName: "Route1_2" }},
        // "97cb6b8d-1c99-0ec3-da05-c22b72fb4085":{departureName:"セントレア", destinationName: "EXPO", routeName: "toExpo", routeId: "T0003_M0010_R0002", defaultPage: { id:101, delaySec: 0, pageName: "Route2_2" }}
        "97cb6b8d-1c99-0ec3-da05-c22b72fb4085":{departureName:"EXPO", destinationName: "セントレア", routeName: "toExpo", routeId: "T0003_M0010_R0002", defaultPage: { id:100, delaySec: 0, pageName: "Route1_2" }},
        "80dbd148-b1a1-fad1-6906-cec0cac08d83":{departureName:"セントレア", destinationName: "EXPO", routeName: "toCentrair", routeId: "T0003_M0010_R0002", defaultPage: { id:101, delaySec: 0, pageName: "Route2_2" }}
      },
      currentPage: null,
      seatId: null,
      seatName: "",
      vehicleId: null,
      token: null,
      userId: null,
      secret: null,
      routeId: null,
      route: {},
      userAttr: null,
      triggerFuncList: {},
      isOpenChoiceShort: false,
      isEraseCookie: false,
    };
  },
  async mounted() {
    console.log("moutend App");
    const self = this;
    parentApp = this;

    // QRコードで取得した値を
    // Cookieで値を保存しCookieから持ってくるようにする

    // cookieとgetパラメータどちらを使うかの判断について
    // 1.cookieがあるか？
    // 2.cookieが有効か？
    // 3.パラメータが有効か？
    // a.cookieがあり有効な値である→cookieの値を利用
    // b.cookieがあるが無効な値(不正な値)がありgetパラメータが有効な値である→getパラメータを使用
    // c.cookieがなくgetパラメータがある→getパラメータを使用
    // a~c以外→エラー
    // 4.属性情報のCookieがあるか?
    // 4-a.なければ登録ページへ移動(orAPIで確認)
    // 4-b.あれば通常のtを開始

    // 1.cookieがあるか？
    let hasCookie = true;
    if(parseInt(this.$route.query.reset) === 1){
      this.deleteCookie();
      console.log("cookie reset");
    }

    if(this.$cookies.isKey('seat') === false || !this.$cookies.get('seat')){
      console.log("no cookie seat " + this.$cookies.get('seat') + ", " + this.$cookies.isKey('seat'));
      hasCookie = false;
    }
    console.log(`cookie seat: ${this.$cookies.get('seat')}`);
    if(this.$cookies.isKey('seatName') === false || !this.$cookies.get('seatName')){
      console.log("no cookie seatName " + this.$cookies.get('seatName') + ", " + this.$cookies.isKey('seatName'));
      hasCookie = false;
    }
    console.log(`cookie seatName: ${this.$cookies.get('seatName')}`);
    if(this.$cookies.isKey('vehicle') === false  || !this.$cookies.get('vehicle')){
      console.log("no cookie vehicle " + this.$cookies.get('vehicle') + ", " + this.$cookies.isKey('vehicle'));
      hasCookie = false;
    }
    console.log(`cookie vehicle: ${this.$cookies.get('vehicle')}`);
    if(this.$cookies.isKey('token') === false  || !this.$cookies.get('token')){
      console.log("no cookie token " + this.$cookies.get('token') + ", " + this.$cookies.isKey('token'));
      hasCookie = false;
    }
    console.log(`cookie token: ${this.$cookies.get('token')}`);
    if(this.$cookies.isKey('user') === false  || !this.$cookies.get('user')){
      console.log("no cookie user " + this.$cookies.get('user') + ", " + this.$cookies.isKey('user'));
      hasCookie = false;
    }
    console.log(`cookie user: ${this.$cookies.get('user')}`);
    if(this.$cookies.isKey('secret') === false  || !this.$cookies.get('secret')){
      console.log("no cookie secret " + this.$cookies.get('secret') + ", " + this.$cookies.isKey('secret'));
      hasCookie = false;
    }
    console.log(`cookie secret: ${this.$cookies.get('secret')}`);
    if(this.$cookies.isKey('route') === false  || !this.$cookies.get('route')){
      console.log("no cookie route " + this.$cookies.get('route') + ", " + this.$cookies.isKey('route'));
      hasCookie = false;
    }
    console.log(`cookie route: ${this.$cookies.get('route')}`);

    console.log(`hasCookie:${hasCookie}`);

    // paramが存在しcookieのデータとparamのデータが違うか?(違ったらparamを使う)
    let isDefferentParam = false;
    if(this.$route.query.s && this.$route.query.s !== this.$cookies.get('seat')){
      isDefferentParam = true;
    }

    if(this.$route.query.v && this.$route.query.v !== this.$cookies.get('vehicle')){
      isDefferentParam = true;
    }

    if(this.$route.query.t && this.$route.query.t !== this.$cookies.get('token')){
      isDefferentParam = true;
    }

    if(this.$route.query.r && this.$route.query.r !== this.$cookies.get('route')){
      isDefferentParam = true;
    }

    console.log(`isDefferentParam:${isDefferentParam}`);

    // 2.cookieが有効か？
    let isValidCookieData = false;
    if(hasCookie === true && isDefferentParam === false){
      const se = this.$cookies.get('seat');
      const sen = this.$cookies.get('seatName');
      const ve = this.$cookies.get('vehicle');
      const to = this.$cookies.get('token');
      const usr = this.$cookies.get('user');
      const sec = this.$cookies.get('secret');
      const rt = this.$cookies.get('route');
      console.log(`get cookie seat:${se}, vehicle:${ve}, token:${to}, user:${usr}, securet:${sec}, route:${rt}`);
      // 新方式では利用していないユーザーIDがリクエストをかけてもページが切り替わらない仕組みになる予定なのでcookieの値を信じることにする
      // const result = await MooxApi.GetUser(se, ve, to, usr, sec);
      // if(result.status === "OK" && result.user === usr){
      //   this.seatId = se;
      //   this.vehicleId = ve;
      //   this.token = to;
      //   this.userId = usr;
      //   this.secret = sec;
      //   isValidCookieData = true;
      // }
      // else if(hasCookie === true && isDefferentParam === true){
      //   this.deleteCookie();
      // }
      // else{
      //   console.log("get cookie is not ok ");
      // }
      this.seatId = se;
      this.seatName = sen;
      this.vehicleId = ve;
      this.token = to;
      this.userId = usr;
      this.secret = sec;
      this.routeId = rt;
      this.route =  this.routeList[rt] ? this.routeList[rt] : null;
      MooxApi.SetUser(se, ve, to, usr, sec, this.route);
      isValidCookieData = true;
    }

    console.log(`isValidCookieData:${isValidCookieData}`);    

    // 3.パラメータが有効か？
    if(isValidCookieData === false){
      this.seatId = this.$route.query.s;
      this.vehicleId = this.$route.query.v;
      this.token = this.$route.query.t;
      this.routeId = this.$route.query.r;
      this.route =  this.routeList[this.routeId] ? this.routeList[this.routeId] : null;
    }

    // テストトリガーの時は処理しない
    console.log("path: " + this.$route.path);
    if(this.$route.path === "/dev/trigger"){
      console.log("trigger mode");
      return;
    }
    if(!this.seatId || !this.vehicleId || !this.token){
      alert("不正なURLです。");
      return;
    }
    console.log(`seat:${this.seatId}, vehicle:${this.vehicleId}, token:${this.token}, route:${this.routeId}`);

    // MooxApi初期化処理
    MooxApi.init();

    // トリガーで動作させる関数の登録
    self.triggerFuncList["CloseChoice"] = function() {
      console.log("trigger CloseChoice");
      self.isOpenChoiceShort = false;
    };
    self.triggerFuncList["OpenChoice"] = function() {
      console.log("trigger OpenChoice");
      self.isOpenChoiceShort = true;
    };
    self.triggerFuncList["OnReset"] = function() {
      self.resetChoise();
    };

    console.log(`user: ${this.userId}, secret: ${this.secret}`);
    // ユーザIDとシークレットの取得
    let user = null;
    if(!this.userId || !this.secret){
      user = await MooxApi.PostUser(this.seatId, this.vehicleId, this.token, this.route);
      this.userId = user.id;
      this.seatName = user.seat;
      this.secret = user.secret;
      console.log(`id;${user.id},secret:${user.secret},seat:${user.seat}`);
    }

    this.$cookies.remove('seat');
    this.$cookies.remove('seatName');
    this.$cookies.remove('vehicle');
    this.$cookies.remove('token');
    this.$cookies.remove('user');
    this.$cookies.remove('secret');
    this.$cookies.remove('route');

    if(this.seatId){
      this.$cookies.set('seat',this.seatId, MooxApi.cookieExpire)
    }
    if(this.seatName){
      this.$cookies.set('seatName',this.seatName, MooxApi.cookieExpire)
    }
    if(this.vehicleId){
      this.$cookies.set('vehicle',this.vehicleId, MooxApi.cookieExpire)
    }
    if(this.token){
      this.$cookies.set('token',this.token, MooxApi.cookieExpire)
    }
    if(this.userId){
      this.$cookies.set('user',this.userId, MooxApi.cookieExpire)
    }
    if(this.secret){
      this.$cookies.set('secret',this.secret, MooxApi.cookieExpire);
    }
    if(this.route){
      this.$cookies.set('route',this.routeId, MooxApi.cookieExpire);
    }

    // MooxApi.SetUserInfo()

    // 4.属性情報のCookieがあるか?
    let hasUserAttribute = true;
    if(this.$cookies.isKey('nationality') === false  || !this.$cookies.get('nationality')){
      console.log("no cookie nationality " + this.$cookies.get('nationality') + ", " + this.$cookies.isKey('nationality'));
      hasUserAttribute = false;
    }
    console.log(`cookie nationality: ${this.$cookies.get('nationality')}`);
    if(this.$cookies.isKey('age') === false  || !this.$cookies.get('age')){
      console.log("no cookie age " + this.$cookies.get('age') + ", " + this.$cookies.isKey('age'));
      hasUserAttribute = false;
    }
    console.log(`cookie age: ${this.$cookies.get('job')}`);
    if(this.$cookies.isKey('job') === false  || !this.$cookies.get('job')){
      console.log("no cookie job " + this.$cookies.get('job') + ", " + this.$cookies.isKey('job'));
      // jobはアンケートの対象から外れたのでチェックを外す
      // hasUserAttribute = false;
    }
    console.log(`cookie job: ${this.$cookies.get('job')}`);
    if(this.$cookies.isKey('gender') === false  || !this.$cookies.get('gender')){
      console.log("no cookie gender " + this.$cookies.get('gender') + ", " + this.$cookies.isKey('gender'));
      hasUserAttribute = false;
    }
    console.log(`cookie gender: ${this.$cookies.get('gender')}`);
    if(this.$cookies.isKey('purpose') === false  || !this.$cookies.get('purpose')){
      console.log("no cookie purpose " + this.$cookies.get('purpose') + ", " + this.$cookies.isKey('purpose'));
      hasUserAttribute = false;
    }
    console.log(`cookie purpose: ${this.$cookies.get('purpose')}`);
    console.log(`has user attribute: ${hasUserAttribute}`);

    // 4-a.なければ登録ページへ移動(orAPIで確認)
    if(hasUserAttribute === false && this.$route.path !== "/setting"){
      this.$router.replace('/setting');
      return;
    }

    // 4-b.あれば通常の更新を開始
    const profile = {
      nationality: this.$cookies.get('nationality'),
      age: this.$cookies.get('age'),
      job: this.$cookies.get('job'),
      gender: this.$cookies.get('gender'),
      purpose:  this.$cookies.get('purpose')
    };
    MooxApi.SetProfile(profile);
    isPreventUpdate = false;
    MooxApi.StartUpdate();
    self.setApiCallback();
  },
  beforeRouteLeave(to, from, next) {
    console.log("move beforeRouteLeave");
    MooxApi.OnLeave();
    next();
  },

  methods: {
    checkTrigger: async function () {
      MooxApi.GetTrigger();
    },

    deleteCookie: function(){
        // 違う情報を読み込んだのでcookieを削除する
        console.log("delete save data");
        this.$cookies.remove('seat');
        this.$cookies.remove('vehicle');
        this.$cookies.remove('token');
        this.$cookies.remove('user');
        this.$cookies.remove('secret');
        this.$cookies.remove('choice');
        // 属性情報
        this.$cookies.remove('nationality');
        this.$cookies.remove('age');
        this.$cookies.remove('job');
        this.$cookies.remove('gender');
        this.$cookies.remove('purpose');
    },
    resetChoise: function(){
      this.$cookies.remove('choice');
    },
    checkPageMove: (contentKey, timeFromTriggered, self) => {
      if (self === undefined) {
        self = this;
      }
      let targetPage = null;
      for (const ii in self.pageList) {
        const p = self.pageList[ii];
        if (p === undefined) {
          continue;
        }
        // TODO: ページチェック
        if (p.key !== contentKey) {
          continue;
        }
        const delayMs = p.delaySec * 1000;
        // console.log(`delayMS: ${delayMs} timeFrommTriggered: ${timeFromTriggered}`);
        if(delayMs < timeFromTriggered){
          targetPage = p;
          break;
        }
      }
      // ルートがなかったらつーとを見て運行ガイドを表示する
      if (targetPage === null && self.route && self.route.defaultPage){
        targetPage = self.route.defaultPage;
      }
      if (targetPage === null) {
        return null;
      }
      // if(self.currentPage && self.currentPage.key === targetPage.key){
      //   return null;
      // }
      if(self.currentPage && self.currentPage.id === targetPage.id){
        return null;
      }
      self.currentPage = targetPage;
      return targetPage;
    },
    setApiCallback: function(){
      const self = parentApp;
      console.log("setApiCallback");
      MooxApi.OnUpdate = function(){
        if(isPreventUpdate === true){
          return;
        }
        const now = new Date();
        // console.log("update " + now);
        const timeDiff = now.getTime() - MooxApi.GetLatestTriggerTime().getTime();
        const contentObj = MooxApi.GetLatestParseContent();
        const target = self.checkPageMove(contentObj ? contentObj.label : null, timeDiff, self);
        if(target){
          console.log("detect target " + target.pageName);
        }
        // http://localhost:8080/?s=dbaa6c8b-5d39-40d5-b3f8-08dbcaeee4fb&v=146d6fb9-e366-41ed-9c19-08dbcaeecc42&t=7d262030dcfcf86ec5830d47d938b01443839a7be39f5b85b77d9804b48cdb6e
        if(target && target.funcName){
          console.log("exec func " + target.funcName);
          const func = self.triggerFuncList[target.funcName];
          if(func){
            func();
          }
          else{
            console.log("関数がありません。 " + target.funcName);
          }
        }
        if(target && target.mode && target.mode === "Redirect" && target.url){
          console.log("Redirect to " + target.url);
          window.location.href = target.url;
          return;
        }
        if (target && target.pageName && self.$route.name !== target.pageName) {
          console.log("move to " + target.pageName);
          // self.$router.push(target.pageName + query);
          self.$router.push({
            name:target.pageName,
            params: {}
            // params: {propSeatId: self.seatId, propVehicleId: self.vehicleId, propToken: self.token, propUserId: self.userId, propSecret: self.secret}
          }).catch(err => {
            console.log(err);
          });
        }
      }

      MooxApi.OnTrigger = (label, time) =>{
          console.log("on trigger " + label + " " + time);
      }
    },
    onChildMessage : function(messageObj){
      if(messageObj.message === "START_UPDATE"){
        console.log("child message: START_UPDATE");
        isPreventUpdate = false;
        MooxApi.StartUpdate();
        parentApp.setApiCallback();
      }
    },

  },
};
</script>
