<template>
	<div>
		<div class="contents_guide" v-if="language === 'Japanese'">

			<header class="header">
				<h1><a href=""><img src="@/assets/img/common/logo.svg" alt="MOOX"></a></h1>
			</header>

			<div class="container_top02">

				<section class="section01">
					<div class="mainTitle"><img src="@/assets/img/route1/1-1/main_logo.svg" alt="MOOX RIDE Navi" />
					</div>
					<p class="guideText01">移動空間の未来を体験</p>
					<p class="guideText02 mt-30">XR技術を用いて、移動空間で商業施設や観光地を魅力的に紹介します。<br>
						スマホでは車内コンテンツに沿って、深堀り情報を更新。車の進行に合わせて表示される内容が変わるのでお見逃しなく！</p>
				</section>

				<section class="section02_guide">
					<div class="titleBox">運行ガイド</div>
					<p class="text-center font-14 guideText03">約5分間</p>
					<div class="guideImgBox mt-30"><img src="@/assets/img/route2/2-2/guide_text01_jp.svg" alt="" />
					</div>
				</section>

			</div>

		</div>
		<div class="contents_guide" v-else>

			<header class="header">
				<h1><a href=""><img src="@/assets/img/common/logo.svg" alt="MOOX"></a></h1>
			</header>

			<div class="container_top02">

				<section class="section01">
					<div class="mainTitle"><img src="@/assets/img/route1/1-1/main_logo.svg" alt="MOOX RIDE Navi" />
					</div>
					<p class="guideText01">Experience the future of<br>mobile space</p>
					<p class="guideText02 mt-30">Using XR technology, we will introduce commercial facilities and
						tourist spots in the mobile space in an attractive manner.<br>The smartphone updates with
						in-vehicle content and provides in-depth information updates. Don’t miss the content displayed
						as the car is moving.</p>

				</section>

				<section class="section02_guide">
					<div class="titleBox_en">Operations Guide</div>
					<p class="text-center font-14 guideText03">Approximately 5 minutes</p>
					<div class="guideImgBox mt-30"><img src="@/assets/img/route2/2-2/guide_text01_en.svg" alt="" />
					</div>
				</section>

			</div>
		</div>
	</div>
</template>

<style scoped></style>

<script>
import MooxApi from '../../MooxApi';

export default {
	name: "Route2_2",
	components: {

	},
	data() {
		return {
			seatId: null,
			vehicleId: null,
			token: null,
			destination: "",
			seat: "",
			language: "Japanese",
		};
	},
	async mounted() {
		console.log("moutend Route2_2");
		MooxApi.StartUpdate();
		console.log(MooxApi.GetRoute());
		this.destination = MooxApi.GetRoute() ? MooxApi.GetRoute().destinationName : "行き先なし";
		this.seat = MooxApi.GetSeatName();
		const profile = await MooxApi.GetProfileFromLocal();
		// 更新した時にAppより先にここが呼ばれるので対処療法的に国だけ取得する
		if (!profile.nationality) {
			profile.nationality = this.$cookies.get('nationality');
		}
		if (profile.nationality === 'Japan') {
			this.language = 'Japanese';
			document.getElementsByTagName('html')[0].lang = 'ja'
		}
		else {
			this.language = 'English';
			document.getElementsByTagName('html')[0].lang = 'en'
		}

	},
	methods: {
		getProfileTest: async function () {
			const profile = await MooxApi.GetProfileFromApi();
			alert("GET " + JSON.stringify(profile));
			console.log(profile);
		}
	}
};
</script>