var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.language === 'Japanese')?_c('div',{staticClass:"contents_fod"},[_vm._m(0),_c('div',{staticClass:"container_close"},[_c('section',{staticClass:"section_close02"},[_c('div',{staticClass:"thanksInner"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"linkBox02"},[_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('p',{staticClass:"link"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onClickEnquete.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/img/route2/2-8/link05_jp.svg"),"alt":"アンケートはこちら"}})])])])])])])]):_c('div',{staticClass:"contents_fod"},[_vm._m(7),_c('div',{staticClass:"container_close"},[_c('section',{staticClass:"section_close02"},[_c('div',{staticClass:"thanksInner"},[_vm._m(8),_vm._m(9),_c('div',{staticClass:"linkBox02"},[_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_c('p',{staticClass:"link"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onClickEnquete.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/img/route2/2-8/link05_en.svg"),"alt":"アンケートはこちら"}})])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header"},[_c('h1',[_c('a',{attrs:{"href":""}},[_c('img',{attrs:{"src":require("@/assets/img/common/logo.svg"),"alt":"MOOX"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"close02Text01"},[_vm._v("｢MOOX-RIDE Navi｣ へのご参加、ありがとうございました。 "),_c('br'),_c('br'),_vm._v(" セントレアにはまだまだたくさんの楽しみ方があります。"),_c('br'),_vm._v(" 是非お立ち寄りください。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"linkBox"},[_c('div',{staticClass:"thanksImg01"},[_c('img',{attrs:{"src":require("@/assets/img/route2/2-8/img01.jpg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"link"},[_c('a',{attrs:{"href":"https://www.centrair.jp/event/enjoy/index.html","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/img/route2/2-8/link01_jp.svg"),"alt":"セントレアの楽しみ方"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"link"},[_c('a',{attrs:{"href":"https://www.centrair.jp/shop-dine/gift/index.html","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/img/route2/2-8/link02_jp.svg"),"alt":"セントレアのおみやげ特集"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"link"},[_c('a',{attrs:{"href":"https://www.centrair.jp/shop-dine/shop/","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/img/route2/2-8/link03_jp.svg"),"alt":"ショップ"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"link"},[_c('a',{attrs:{"href":"https://www.centrair.jp/event/index.html","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/img/route2/2-8/link04_jp.svg"),"alt":"イベント・キャンペーン"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header"},[_c('h1',[_c('a',{attrs:{"href":""}},[_c('img',{attrs:{"src":require("@/assets/img/common/logo.svg"),"alt":"MOOX"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"close02Text01"},[_vm._v("Thank you for participating on the “MOOX RIDE Navi”."),_c('br'),_vm._v("There are many more ways to enjoy Centrair."),_c('br'),_vm._v("Please visit us again.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"linkBox"},[_c('div',{staticClass:"thanksImg01"},[_c('img',{attrs:{"src":require("@/assets/img/route2/2-8/img01.jpg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"link"},[_c('a',{attrs:{"href":"https://www.centrair.jp/event/enjoy/index.html","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/img/route2/2-8/link01_en.svg"),"alt":"How to enjoy Centrair"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"link"},[_c('a',{attrs:{"href":"https://www.centrair.jp/shop-dine/gift/index.html","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/img/route2/2-8/link02_en.svg"),"alt":"Centrair souvenir special feature"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"link"},[_c('a',{attrs:{"href":"https://www.centrair.jp/shop-dine/shop/","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/img/route2/2-8/link03_en.svg"),"alt":"Shop"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"link"},[_c('a',{attrs:{"href":"https://www.centrair.jp/event/index.html","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/img/route2/2-8/link04_en.svg"),"alt":"Event/Campaign"}})])])
}]

export { render, staticRenderFns }