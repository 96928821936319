// VueでJsの中に静的と動的に外部Jsを読み込む書き方
// https://qiita.com/gakuseikai/items/fec6967c95c82e600f48
const MooxApi = {};

let seatId = null;
let seatName = "";
let vehicleId = null;
let token = "";
let userId = "";
let route = {};
let profile = {
    nationality: "",
    age: 0,
    job: "",
    gender: "",
    purpose: ""
};
const apiEndpoint =  "https://api.tb.compass-web.jp";
const uriTrigger =  "/api/v1/triggers";
const uriUser = "/api/v2/users";
const uriChoice = "/api/v1/choices";

// const attributeServerEndpoint = "https://tb-attribute-server-dummy.azurewebsites.net/api/stage/";
const attributeServerEndpoint = "https://cb19aomjjd.execute-api.ap-northeast-1.amazonaws.com/Prod/";
const uriAtrribute = "atr";

let clientSecret = ""
let triggers = [];
let lastUpdateTime = new Date('2000-02-01T00:00:00');
MooxApi.updateTimerId = null;
MooxApi.updateTriggerTimerId = null;
let latestTriggerContent = null;
let latestParseContent = null;
let triggerTime = new Date('2000-02-01T00:00:00');

MooxApi.OnUpdate = null;
MooxApi.OnTrigger = null;
MooxApi.OnAfterUpdateTrigger = null;
MooxApi.cookieExpire = 60 * 60 * 2;
MooxApi.isUpdating = false;

// const sleep = time => new Promise(resolve => setTimeout(resolve, time));

MooxApi.init = function(){
    console.log("Moox API Init.");
    // クライアントシークレットの作成

};

MooxApi.StartUpdate = () => {
    if(MooxApi.isUpdating === true){
        console.log(`update prevented ${MooxApi.isUpdating}`);
    }
    MooxApi.isUpdating = true;
    console.log("Moox API start update");

    if(MooxApi.updateTimerId === null){
        MooxApi.Update();
    }
    // else{
    //     console.log("update tiemr exist " + MooxApi.updateTimerId );
    // }
    if(MooxApi.updateTriggerTimerId  === null){
        MooxApi.UpdateTrigger();
    }
    // else{
    //     console.log("update trigger tiemr exist " + MooxApi.updateTriggerTimerId );
    // }
}

MooxApi.Update = async () => {
    MooxApi.updateTimerId = setTimeout(async () => {
        if(MooxApi.OnUpdate){
            MooxApi.OnUpdate();
        }
        MooxApi.Update();
    }, 100);
};

MooxApi.UpdateTrigger = () => {
    MooxApi.updateTriggerTimerId = setTimeout(async () => {
        await MooxApi.UpdateTriggerData();
        if(MooxApi.OnAfterUpdateTrigger){
            MooxApi.OnAfterUpdateTrigger(triggers);
        }
        MooxApi.UpdateTrigger();
    }, 2000);
};

const ParseContentData = function(content){
    const contentList = content.split(':');
    console.log(`contentList↓ (len:${contentList.length})`);
    console.log(contentList);
    if(contentList.length === 4){
        // contentがCentrairCentrair2023 Long 01:2024-02-05 20:25:40になっていることを想定(時刻はJST)
        const dateHour = contentList[1].split(' ');
        const timeStr = `${dateHour[0]}T${dateHour[1]}:${contentList[2]}:${contentList[3]}+0900`;
        triggerTime = new Date(timeStr);
    }
    else if(contentList.length === 2){
        // contentがCentrair2023 Short 01:2023-09-30-14-01-30になっていることを想定(時刻はJST)
        const timeArray = contentList[1].split('-');
        const timeStr = `${timeArray[0]}-${timeArray[1]}-${timeArray[2]}T${timeArray[3]}:${timeArray[4]}:${timeArray[5]}+0900`;
        triggerTime = new Date(timeStr);
    }
    return {label: contentList[0], time: triggerTime};
};

MooxApi.UpdateTriggerData = async () => {
    triggers = await MooxApi.GetTrigger();
    if(triggers){
        // console.log("↓triggers");
        // console.log(triggers);
        triggers.forEach((tr) => {
            let triggeredAt = new Date(tr.date);
            // console.log(tr);
            // console.log(triggeredAt);
            if (lastUpdateTime.getTime() < triggeredAt.getTime()) {
                console.log("トリガー更新");
                lastUpdateTime = triggeredAt;
                latestTriggerContent = tr.content;
                // console.log("content: " + tr.content);
                // const jmsData = ParseJMSData(tr.content);
                // if(MooxApi.OnTrigger){
                //     MooxApi.OnTrigger(jmsData.label, jmsData.time);
                // }
                const contentData = ParseContentData(tr.content);
                console.log(`ParseContentData: ${contentData.time} triggerTime:${triggerTime}`);
                latestParseContent = contentData;
                if(MooxApi.OnTrigger){
                    MooxApi.OnTrigger(latestTriggerContent, lastUpdateTime);
                }
                console.log('trigger更新');
            }

        });
    }
    else{
        console.log("トリガーが取得できません");
        triggers = [];
        return;
    }
};

MooxApi.OnLeave = () => function(){
    // console.log("OnLeave");
    if (MooxApi.updateTimerId) {
        // console.log("stop timer " + MooxApi.updateTimerId);
        clearTimeout(MooxApi.updateTimerId);
}
    if(MooxApi.updateTriggerTimerId){
        // console.log("stop timer " + MooxApi.updateTriggerTimerId);
        clearTimeout(MooxApi.updateTriggerTimerId);
    }
};

MooxApi.GetTrigger = async function(){
    let trs = {};
    console.log(`vehicle: ${vehicleId}, seat:${seatId}, token:${token}, user:${userId}, client_secret${clientSecret}`);
    const response = await fetch(apiEndpoint + uriTrigger,
        {
            headers: {
                "Content-Type": "application/json",
                "vehicle": vehicleId,
                "seat": seatId,
                "user": userId,
                "token": token,
                "client_secret": clientSecret,
            },
        });
    // console.log("response");
    // console.log(response.json());
    const responseText = await response.text();
    // console.log(responseText);
    this.responseString = responseText;
    const json = JSON.parse(responseText);
    if(json.status === "OK" && json.triggers){
        trs = json.triggers;
    }
    else{
        trs = [];
    }
    return trs;
};

// MooxApi.SetUserInfo = function(se, ve, to, usr, sec){

// };

MooxApi.PostUser = async function(se, ve, to, rt){
    let user = {};
    seatId = se;
    vehicleId = ve;
    token = to;
    route = rt;
    console.log(`rt:${rt}`);
    const body = {seat:se,vehicle:ve,token:to};
    const response = await fetch(apiEndpoint + uriUser,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
    // console.log("response");
    const responseText = await response.text();
    console.log(responseText);
    this.responseString = responseText;
    const json = JSON.parse(responseText);
    if(json.status === "OK" && json.id && json.secret){
        userId = json.id;
        clientSecret = json.secret;
        seatName = json.seat ? json.seat : "";
        user = {id: json.id, secret: json.secret, seat:json.seat};
        console.log(`user:${userId},sec:${clientSecret},seat:${user.seat}`);
    }
    return user;
};

MooxApi.SetUser = function(se, ve, to, usr, sec, rt){
    seatId = se;
    vehicleId = ve;
    token = to;
    userId = usr;
    clientSecret = sec;
    route = rt;
    console.log(`rt:${rt}`);
}

MooxApi.PostChoice = async function(theme, choice){
    console.log("PostChoice");
    const body = {choice:choice,token:token,theme:theme};
    const response = await fetch(apiEndpoint + uriChoice,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "seat": seatId,
                "vehicle": vehicleId,
                "token": token,
                "client_secret": clientSecret
            },
            body: JSON.stringify(body),
        });
    const responseText = await response.text();
    // console.log("response");
    console.log(responseText);
    this.responseString = responseText;
    const json = JSON.parse(responseText);
    if(json.status !== "OK"){
        return false;
    }
    if(!json.seat && !json.vehicle){
        return false;
    }
    // TODO:vehicleとseatのチェックをするか検討?
    return true;
}

MooxApi.GetLatestContent = function(){
    return latestTriggerContent;
};

MooxApi.GetLatestParseContent = function(){
    return latestParseContent;
}

MooxApi.GetLatestTriggerTime = function(){
    return triggerTime;
};

MooxApi.GetTriggers = function(){
    return triggers;
}

MooxApi.GetRoute = function(){
    return route;
}

MooxApi.GetSeatName = function(){
    return seatName;
}

MooxApi.PostProfile = async function(pro){
    // {
    //     "identification" : "t001_v002_r003",
    //     "boarding": "Expo",
    //     "seat": "A1”,
    //     "nationality": "Japan",
    //     "age": 30,
    //     "job": "Engineer",
    //     "sex": "Male",
    //     "purpose": "MarketingReserch",
    // }
    profile = pro;
    const body = {
        identification: route.routeId,
        route: route.routeName,
        seat: isNaN(parseInt(seatName)) === false ? parseInt(seatName) : seatName,
        nationality: pro.nationality,
        sex: pro.gender,
        age: pro.age,
        job: pro.job,
        purpose: pro.purpose
    };
    console.log(JSON.stringify(body));
    const response = await fetch(attributeServerEndpoint + uriAtrribute,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body),
        });
    const responseText = await response.text();
    console.log("response");
    console.log(responseText);
    this.responseString = responseText;
    const json = JSON.parse(responseText);
    if(json.message && json.message !== "ok" && json.message !== "OK"){
        return false;
    }
    return true;
}

MooxApi.GetProfileFromApi = async function(){
    // =Expo&identification=t001_v002_r003&seat=A1
    const params = {identification: route.routeId, seat : seatName, route: route.routeName};
    const query = new URLSearchParams(params);
    
    const response = await fetch (attributeServerEndpoint + uriAtrribute + "?" + query);
    const responseJson = await response.json();
    console.log(responseJson);
    return responseJson;
}

MooxApi.GetProfileFromLocal = function(){
    return profile;
}


MooxApi.SetProfile = function(pro){
    profile = pro;
}

MooxApi.RedirectToEnquite = function(attr){
    const profile = MooxApi.GetProfileFromLocal();
    console.log(profile);
    const age = attr.age.find((e) => e.value === profile.age);
    const nationality = attr.countries.find((e) => e.value === profile.nationality);
    const gender = attr.gender.find((e) => e.value === profile.gender);
    const purpose = attr.purpose.find((e) => e.value === profile.purpose);

    // let url = "https://docs.google.com/forms/d/e/1FAIpQLScxrZhbTsWSimEWJ2O-myzmlYnOZt8ngtJosD7hMH3wD1UHLA/viewform?usp=pp_url";
    let url = "https://docs.google.com/forms/d/e/1FAIpQLSej8q6w2AvWzE_D0SFfkW7UHFn8ohsvacH0tLm9KUOPAcSTUg/viewform?usp=pp_url"; 
    "&entry.279591745=Japan"
    "&entry.537851222=Male"
    "&entry.1806936955=Under-19"
    "&entry.1081196903=Attend-events"
    if(nationality && nationality.enq){
        url += "&entry.279591745=" + nationality.enq;
    }
    if(gender && gender.enq){
        url += "&entry.537851222=" + gender.enq;
    }
    if(age && age.enq){
        url += "&entry.1806936955=" + age.enq;
    }
    if(purpose && purpose.enq){
        url += "&entry.1081196903=" + purpose.enq;
    }
    url = encodeURI(url);
    // alert(url);
    // window.open(url, '_blank');
    window.location.href = url;
}


export default MooxApi;