<template>
	<div>
		<div class="contents_cm" v-if="language === 'Japanese'">

			<header class="header">
				<h1><a href=""><img src="@/assets/img/common/logo.svg" alt="MOOX"></a></h1>
			</header>

			<div class="container_top02">

				<section class="section_cm01-5">
					<p class="cmText01">CM</p>
					<div class="cmInner1-5">

						<div class="cmImgBox01-5"><a href="https://www.centrair.jp/shop-dine/restaurant/" target="_blank"><img src="@/assets/img/route1/1-5/cm01_jp.svg" alt=""/></a></div>
						<div class="cmImgBox01-5"><a href="https://www.centrair.jp/event/enjoy/foo/" target="_blank"><img src="@/assets/img/route1/1-5/cm02_jp.svg" alt=""/></a></div>
						<div class="cmImgBox01-5"><a href="https://www.centrair.jp/shop-dine/gift/" target="_blank"><img src="@/assets/img/route1/1-5/cm03_jp.svg" alt=""/></a></div>

					</div>
				</section>

			</div>

		</div>
		<div class="contents_cm" v-else>

			<header class="header">
				<h1><a href=""><img src="@/assets/img/common/logo.svg" alt="MOOX"></a></h1>
			</header>

			<div class="container_top02">

				<section class="section_cm01-5">
					<p class="cmText01">CM</p>
					<div class="cmInner1-5">

						<div class="cmImgBox01-5"><a href="https://www.centrair.jp/shop-dine/restaurant/" target="_blank"><img src="@/assets/img/route1/1-5/cm01_en.svg" alt=""/></a></div>
						<div class="cmImgBox01-5"><a href="https://www.centrair.jp/event/enjoy/foo/" target="_blank"><img src="@/assets/img/route1/1-5/cm02_en.svg" alt=""/></a></div>
						<div class="cmImgBox01-5"><a href="https://www.centrair.jp/shop-dine/gift/" target="_blank"><img src="@/assets/img/route1/1-5/cm03_en.svg" alt=""/></a></div>

					</div>
				</section>

			</div>

		</div>

	</div>
</template>
<style scoped></style>
<script>
import MooxApi from '../../MooxApi';

export default {
	name: "Route1_5",
	components: {

	},
	data() {
		return {
			seatId: null,
			vehicleId: null,
			token: null,
			destination: "",
			seat: "",
			language: "Japanese",
		};
	},
	async mounted() {
		console.log("moutend Route1_5");
		MooxApi.StartUpdate();
		console.log(MooxApi.GetRoute());
		this.destination = MooxApi.GetRoute() ? MooxApi.GetRoute().destinationName : "行き先なし";
		this.seat = MooxApi.GetSeatName();
		const profile = await MooxApi.GetProfileFromLocal();
		// 更新した時にAppより先にここが呼ばれるので対処療法的に国だけ取得する
		if(!profile.nationality){
			profile.nationality = this.$cookies.get('nationality');
		}
		if(profile.nationality === 'Japan'){
			this.language = 'Japanese';
			document.getElementsByTagName('html')[0].lang = 'ja'
		}
		else{
			this.language = 'English';
			document.getElementsByTagName('html')[0].lang = 'en'
		}
	},
	methods: {
	}
};
</script>