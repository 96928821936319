import { render, staticRenderFns } from "./route1-6.vue?vue&type=template&id=e02af548&scoped=true&"
import script from "./route1-6.vue?vue&type=script&lang=js&"
export * from "./route1-6.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e02af548",
  null
  
)

export default component.exports