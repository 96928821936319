<!-- ベーステンプレート https://github.com/BulmaTemplates/bulma-templates/blob/master/templates/login.html -->
<template>
    <div>
        <div class="contents_wait" v-show="isLoading">

            <header class="header">
                <h1><a href=""><img src="@/assets/img/common/logo.svg" alt="MOOX"></a></h1>
            </header>

            <div class="container_top02">

                <section class="section_wait">
                    <div class="cmInner">

                        <div class="mainTitle"><img src="@/assets/img/route1/1-1/main_logo.svg" alt="MOOX RIDE Navi" />
                        </div>
                        <p class="waitText01">処理中です。<br>しばらくお待ちください。</p>
                        <p class="waitText01">Processing is in progress.<br>Please wait a moment.</p>
                    </div>
                </section>

            </div>

        </div>
        <div class="contents" v-show="!isLoading">
            <header class="header">
                <h1><a href=""><img src="@/assets/img/common/logo.svg" alt="MOOX"></a></h1>
            </header>

            <div class="container_top">

                <section class="section01">
                    <div class="mainTitle"><img src="@/assets/img/route1/1-1/main_logo.svg" alt="MOOX RIDE Navi" /></div>
                    <p class="topText01">座席{{ seatName }}番にお座りください<br>Please sit in seat {{ seatName }}</p>
                    <p class="topText02">以下項目をご入力ください。<br><span>Please enter the following items.</span></p>

                    <div class="selectBox">
                        <p class="topText03">お住まいの国、地域<br><span>Area</span></p>
                        <select name="country" id="select-country" v-model="nationality" v-bind:disabled="isLoading">
                            <option value="" selected>選択してください/Please select</option>
                            <option v-for="country in optionCountries" v-bind:value="country.value" v-bind:key="country.id">
                                {{ country.name }}
                            </option>
                        </select>
                    </div>

                    <div class="selectBox">
                        <p class="topText03">性別<br><span>Gender</span></p>
                        <select name="gender" id="select-gender" v-model="gender" v-bind:disabled="isLoading">
                            <option value="" selected>選択してください/Please select</option>
                            <option v-for="gen in optionGender" v-bind:value="gen.value" v-bind:key="gen.id">
                                {{ gen.name }}
                            </option>
                        </select>
                    </div>

                    <div class="selectBox">
                        <p class="topText03">年齢<br><span>Age</span></p>
                        <select name="age" id="select-age" v-model="age" v-bind:disabled="isLoading">
                            <option value="" selected>選択してください/Please select</option>
                            <option v-for="ag in optionAge" v-bind:value="ag.value" v-bind:key="ag.id">
                                {{ ag.name }}
                            </option>
                        </select>
                    </div>

                    <!-- <div class="selectBox">
                        <p class="topText03">職業<br><span>Profession</span></p>
                        <select name="country" id="select-country" v-model="job" v-bind:disabled="isLoading">
                            <option value="" selected>選択してください/Please select</option>
                            <option v-for="jo in optionJobs" v-bind:value="jo.value" v-bind:key="jo.id">
                                {{ jo.name }}
                            </option>
                        </select>
                    </div> -->

                    <div class="selectBox">
                        <p class="topText03">目的<br><span>Purpose</span></p>
                        <select name="objective" id="select-objective" v-model="purpose" v-bind:disabled="isLoading">
                            <option value="" selected>選択してください/Please select</option>
                            <option v-for="pur in optionPourpse" v-bind:value="pur.value" v-bind:key="pur.id">
                                {{ pur.name }}
                            </option>
                        </select>
                    </div>

                    <button @click.prevent.stop="onClickSetting" v-bind:disabled="isLoading" class="confiBox">
                        <img src="@/assets/img/route1/1-1/confirmation.svg" alt="" />
                    </button>

                </section>

            </div>

        </div>
    </div>
</template>
  
<style lang="scss">
</style>
  
<script>
import MooxApi from '../../MooxApi';
import Attribute from "@/assets/attribute.json";

export default {
    name: "UserSetting",
    components: {

    },
    props: ['seatId', 'vehicleId', 'token', 'user'],
    data() {
        return {
            nationality: '',
            optionCountries: [],
            age: '',
            optionAge: [],
            job: '',
            optionjobs: [],
            gender: '',
            optionGender: [],
            purpose: '',
            optionPourpse: [],
            isLoading: false,
            seatName: ""
        };
    },
    async mounted() {
        console.log("moutend UserSetting");
        console.log('open sample page');
        this.optionCountries = Attribute.countries;
        this.optionAge = Attribute.age;
        this.optionJobs = Attribute.jobs;
        this.optionGender = Attribute.gender;
        this.optionPourpse = Attribute.purpose;


        this.seatName = MooxApi.GetSeatName();
    },
    methods: {
        onClickSetting: async function () {
            console.log("nationality: " + this.nationality);
            console.log("age: " + this.age);
            console.log("job: " + this.job);
            console.log("gender: " + this.gender );
            console.log("purpose: " + this.purpose);

            let isFilled = true;
            let message = "";
            if(!this.nationality){
                isFilled = false;
                message += "お住まいの国、地域\nArea\n\n";
            }
            if(!this.gender){
                isFilled = false;
                message += "性別\nGender\n\n";
            }
            if(!this.age){
                isFilled = false;
                message += "年齢\nAge\n\n";
            }

            // if(!this.job){
            //     isFilled = false;
            //     message += "職業\nProfession\n\n";
            // }

            if(!this.purpose){
                isFilled = false;
                message += "目的\nPurpose\n\n";
            }
            if(isFilled !== true){
                alert("以下の項目を入力してください\nPrease Input Item(s) blow\nPlease select the following items to proceed.\n\n" + message);
                return;
            }
            this.isLoading = true;
            const result = await this.sendSetting();
            if (result === false) {
                alert("設定時にエラーが発生しました。");
            }
            // TODO: 次のページへ遷移する
            // 更新を有効にする
            // this.isLoading = false;

            this.$emit('on-message', { message: "START_UPDATE" });
        },
        sendSetting: async function () {

            console.log(`国籍:${this.nationality}, 年代:${this.age}, 職業:${this.job}`);
            const result = await MooxApi.PostProfile({
                nationality: this.nationality,
                age: this.age,
                job: this.job,
                gender: this.gender,
                purpose: this.purpose
            });
            this.$cookies.set('nationality', this.nationality, MooxApi.cookieExpire);
            this.$cookies.set('age', this.age, MooxApi.cookieExpire);
            this.$cookies.set('job', this.job, MooxApi.cookieExpire);
            this.$cookies.set('gender', this.gender, MooxApi.cookieExpire);
            this.$cookies.set('purpose', this.purpose, MooxApi.cookieExpire);
            return result;
        }
    }
};
</script>