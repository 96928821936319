import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/home.vue'
import Page1 from '../views/page.vue'
import Choice from '../views/choice.vue'
import TriggerTest from '../views/TriggerTest.vue'
import UserSetting from '../views/common/user_setting.vue'
import SeatInfomation from '../views/common/seat_infomation.vue'
import AbstructSmsToExpo from '../views/sms_to_expo/abstruct.vue'
import Route1_2 from '../views/sms_to_expo/route1-2.vue'
import Route1_3 from '../views/sms_to_expo/route1-3.vue'
import Route1_4 from '../views/sms_to_expo/route1-4.vue'
import Route1_5 from '../views/sms_to_expo/route1-5.vue'
import Route1_6 from '../views/sms_to_expo/route1-6.vue'
import Route1_7 from '../views/sms_to_expo/route1-7.vue'
import Route1_8 from '../views/sms_to_expo/route1-8.vue'
import Route1_9 from '../views/sms_to_expo/route1-9.vue'
import Route2_2 from '../views/sms_to_centrair/route2-2.vue'
import Route2_3 from '../views/sms_to_centrair/route2-3.vue'
import Route2_4 from '../views/sms_to_centrair/route2-4.vue'
import Route2_5 from '../views/sms_to_centrair/route2-5.vue'
import Route2_6 from '../views/sms_to_centrair/route2-6.vue'
import Route2_7 from '../views/sms_to_centrair/route2-7.vue'
import Route2_8 from '../views/sms_to_centrair/route2-8.vue'

// import VueGtag from 'vue-gtag'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      // meta: { title: 'ホーム' },
      props: true
    },
    {
      path: '/page',
      name: 'Page1',
      component: Page1,
      meta: { title: 'サンプルページ' },
      props: true
    },
    {
      path: '/choice',
      name: 'Choice',
      component: Choice,
      meta: { title: '投票サンプルページ' },
      props: true
    },
    {
      path: '/dev/trigger',
      name: 'TriggerTest',
      component: TriggerTest,
      meta: { title: '発報' },
      props: true
    },
    {
      path: '/setting',
      name: 'UserSetting',
      component: UserSetting,
      meta: { title: 'MOOX ユーザー設定' },
      props: true
    },
    {
      path: '/seat',
      name: 'SeatInfomation',
      component: SeatInfomation,
      meta: { title: 'MOOX 座席情報' },
      props: true
    },
    {
      path: '/sms/expo/abstruct',
      name: 'AbstructSmsToExpo',
      component: AbstructSmsToExpo,
      meta: { title: 'MOOX' },
      props: true
    },
    {
      path: '/sms/expo/route1_2',
      name: 'Route1_2',
      component: Route1_2,
      meta: { title: 'MOOX' },
      props: true
    },
    {
      path: '/sms/expo/route1_3',
      name: 'Route1_3',
      component: Route1_3,
      meta: { title: 'MOOX' },
      props: true
    },
    {
      path: '/sms/expo/route1_4',
      name: 'Route1_4',
      component: Route1_4,
      meta: { title: 'MOOX' },
      props: true
    },
    {
      path: '/sms/expo/route1_5',
      name: 'Route1_5',
      component: Route1_5,
      meta: { title: 'MOOX' },
      props: true
    },
    {
      path: '/sms/expo/route1_6',
      name: 'Route1_6',
      component: Route1_6,
      meta: { title: 'MOOX' },
      props: true
    },
    {
      path: '/sms/expo/route1_7',
      name: 'Route1_7',
      component: Route1_7,
      meta: { title: 'MOOX' },
      props: true
    },
    {
      path: '/sms/expo/route1_8',
      name: 'Route1_8',
      component: Route1_8,
      meta: { title: 'MOOX' },
      props: true
    },
    {
      path: '/sms/expo/route1_9',
      name: 'Route1_9',
      component: Route1_9,
      meta: { title: 'MOOX' },
      props: true
    },
    {
      path: '/sms/centtrair/route2_2',
      name: 'Route2_2',
      component: Route2_2,
      meta: { title: 'MOOX' },
      props: true
    },
    {
      path: '/sms/centtrair/route2_3',
      name: 'Route2_3',
      component: Route2_3,
      meta: { title: 'MOOX' },
      props: true
    },
    {
      path: '/sms/centtrair/route2_4',
      name: 'Route2_4',
      component: Route2_4,
      meta: { title: 'MOOX' },
      props: true
    },
    {
      path: '/sms/centtrair/route2_5',
      name: 'Route2_5',
      component: Route2_5,
      meta: { title: 'MOOX' },
      props: true
    },
    {
      path: '/sms/centtrair/route2_6',
      name: 'Route2_6',
      component: Route2_6,
      meta: { title: 'MOOX' },
      props: true
    },
    {
      path: '/sms/centtrair/route2_7',
      name: 'Route2_7',
      component: Route2_7,
      meta: { title: 'MOOX' },
      props: true
    },
    {
      path: '/sms/centtrair/route2_8',
      name: 'Route2_8',
      component: Route2_8,
      meta: { title: 'MOOX' },
      props: true
    },
  ],
  scrollBehavior() {
    return { x:0, y: 0 };
  },
});

// Vueでvue-gtagを利用してGoogle Analytics4(GA4)の計測を行う方法
// https://kagasu.hatenablog.com/entry/2022/07/27/112623
// TODO: Googleタグを取得して設定する
// Vue.use(VueGtag, {
//   config: {
//     id: ''
//   }
// }, router);

const DEFAULT_TITLE = '常滑'

router.afterEach((to) => {
  const title = to.meta.title ? `${ to.meta.title }|${ DEFAULT_TITLE }` : DEFAULT_TITLE
  document.title = title;
});


export default router;
