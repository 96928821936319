<template>
	<div>
		<div class="contents_cm" v-if="language === 'Japanese'">

			<header class="header">
				<h1><a href=""><img src="@/assets/img/common/logo.svg" alt="MOOX"></a></h1>
			</header>

			<div class="container_tokoname">

				<section class="section_tokoname01">

					<div class="tokonameInner">
						<p class="tokonameText01">常滑市</p>
						<div class="tokonameMapBox"><img src="@/assets/img/route1/1-4/map.svg" alt="" /></div>
						<div class="linkBox">
							<div class="tokonameImg01"><img src="@/assets/img/route1/1-4/img01.jpg" alt="" /></div>
						</div>

						<div class="linkBox02">
							<p class="link"><a href="https://www.tokoname-kankou.net/course/" target="_blank"><img
										src="@/assets/img/route1/1-4/link01.svg" alt="モデルコース" /></a></p>
							<p class="link"><a href="https://www.tokoname-kankou.net/pamphlet/" target="_blank"><img
										src="@/assets/img/route1/1-4/link02.svg" alt="観光パンフレット" /></a></p>
							<p class="link"><a href="https://www.tokoname-kankou.net/gourmet/" target="_blank"><img
										src="@/assets/img/route1/1-4/link03.svg" alt="グルメ" /></a></p>
							<p class="link"><a href="https://www.tokoname-kankou.net/souvenir/" target="_blank"><img
										src="@/assets/img/route1/1-4/link04.svg" alt="お土産" /></a></p>
						</div>

					</div>

				</section>

			</div>

		</div>
		<div class="contents_cm" v-else>

			<header class="header">
				<h1><a href=""><img src="@/assets/img/common/logo.svg" alt="MOOX"></a></h1>
			</header>

			<div class="container_tokoname">

				<section class="section_tokoname01">

					<div class="tokonameInner">
						<p class="tokonameText01">Tokoname city</p>
						<div class="tokonameMapBox"><img src="@/assets/img/route1/1-4/map_en.svg" alt="" /></div>
						<div class="linkBox">
							<div class="tokonameImg01"><img src="@/assets/img/route1/1-4/img01.jpg" alt="" /></div>
						</div>

						<div class="linkBox02">
							<p class="link"><a href="https://www.tokoname-kankou.net/course/" target="_blank"><img
										src="@/assets/img/route1/1-4/link01_en.svg" alt="Model course" /></a></p>
							<p class="link"><a href="https://www.tokoname-kankou.net/pamphlet/" target="_blank"><img
										src="@/assets/img/route1/1-4/link02_en.svg" alt="Tourist brochure" /></a></p>
							<p class="link"><a href="https://www.tokoname-kankou.net/gourmet/" target="_blank"><img
										src="@/assets/img/route1/1-4/link03_en.svg" alt="Gourmet" /></a></p>
							<p class="link"><a href="https://www.tokoname-kankou.net/souvenir/" target="_blank"><img
										src="@/assets/img/route1/1-4/link04_en.svg" alt="Souvenir" /></a></p>
						</div>

					</div>

				</section>
			</div>

		</div>

	</div>
</template>
<style scoped></style>
<script>
import MooxApi from '../../MooxApi';

export default {
	name: "Route1_4",
	components: {

	},
	data() {
		return {
			seatId: null,
			vehicleId: null,
			token: null,
			destination: "",
			seat: "",
			language: "Japanese",
		};
	},
	async mounted() {
		console.log("moutend Route1_4");
		MooxApi.StartUpdate();
		console.log(MooxApi.GetRoute());
		this.destination = MooxApi.GetRoute() ? MooxApi.GetRoute().destinationName : "行き先なし";
		this.seat = MooxApi.GetSeatName();
		const profile = await MooxApi.GetProfileFromLocal();
		// 更新した時にAppより先にここが呼ばれるので対処療法的に国だけ取得する
		if(!profile.nationality){
			profile.nationality = this.$cookies.get('nationality');
		}
		if (profile.nationality === 'Japan') {
			this.language = 'Japanese';
			document.getElementsByTagName('html')[0].lang = 'ja'
		}
		else {
			this.language = 'English';
			document.getElementsByTagName('html')[0].lang = 'en'
		}
	},
	methods: {
	}
};
</script>