<template>
    <div class="wrapper">
        <div>
            テストページ1
        </div>
        <div>
            <router-link to="/">戻る</router-link>
        </div>
    </div><!-- wrapper -->
</template>
  
<style></style>
  
<script>

export default {
    name: "Page1",
    components: {

    },
    props: ['seatId','vehicleId','token'],
    data() {
        return {
        };
    },
    async mounted() {
        console.log("moutend Page1"); 
       console.log('open sample page');
    },
    methods: {

    }
};
</script>