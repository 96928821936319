import { render, staticRenderFns } from "./route2-3.vue?vue&type=template&id=5c4c743e&scoped=true&"
import script from "./route2-3.vue?vue&type=script&lang=js&"
export * from "./route2-3.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c4c743e",
  null
  
)

export default component.exports