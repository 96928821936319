<template>
	<div class="contents">

		<header class="header">
		</header>

		<div class="container_top">

			<section class="section01">
				<p class="topText01">移動空間の未来を体験</p>
				<p class="topText02 mt-30">XR技術を用いて、移動空間で商業施設や観光地を魅力的に紹介します。<br>
					スマホでは車内コンテンツに沿って、深堀り情報を更新。車の進行に合わせて表示される内容が変わるのでお見逃しなく。</p>
				<p>
					目的地:{{ destination }}<br>
					座席: {{ seat }}<br>
					<button @click="getProfileTest()">プロフィール取得テスト</button><br>
				</p>
			</section>

		</div>


	</div>
</template>
<style scoped>

</style>
<script>
import MooxApi from '../../MooxApi';

export default {
	name: "AbstructSmsToExpo",
	components: {

	},
	data() {
		return {
			choice: 0,
			themeId: "73505162-f3ff-47e5-8f15-08dbc3c98763",
			seatId: null,
			vehicleId: null,
			token: null,
			destination: "",
			seat: "",
		};
	},
	async mounted() {
		console.log("moutend AbstructSmsToExpo");
		MooxApi.StartUpdate();
		console.log(MooxApi.GetRoute());
		this.destination = MooxApi.GetRoute() ? MooxApi.GetRoute().destinationName : "行き先なし";
		this.seat = MooxApi.GetSeatName();
	},
	methods: {
		getProfileTest: async function(){
			const profile = await MooxApi.GetProfileFromApi();
			alert("GET " + JSON.stringify(profile));
			console.log(profile);
		}
	}
};
</script>