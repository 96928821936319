var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.language === 'Japanese')?_c('div',{staticClass:"contents_close"},[_vm._m(0),_c('div',{staticClass:"container_top02"},[_c('section',{staticClass:"section01"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('button',{staticClass:"closeLinkBox02",on:{"click":_vm.onClickEnquete}},[_c('img',{attrs:{"src":require("@/assets/img/route1/1-9/link02.svg"),"alt":""}})])])])]):_c('div',{staticClass:"contents_close"},[_vm._m(5),_c('div',{staticClass:"container_top02"},[_c('section',{staticClass:"section01"},[_vm._m(6),_vm._m(7),_vm._m(8),_c('p',{staticClass:"closeText01"},[_vm._v("Thank you for participating on the “MOOX RIDE Navi”.We wish you find great business opportunities at the exhibition.")]),_c('button',{staticClass:"closeLinkBox02",on:{"click":_vm.onClickEnquete}},[_c('img',{attrs:{"src":require("@/assets/img/route1/1-9/link02_en.svg"),"alt":""}})])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header"},[_c('h1',[_c('a',{attrs:{"href":""}},[_c('img',{attrs:{"src":require("@/assets/img/common/logo.svg"),"alt":"MOOX"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"closeText01"},[_vm._v("Aichi Sky Expoでは、"),_c('br'),_vm._v(" 2024年3月13日(水)～15日(金)"),_c('br'),_vm._v(" 「SMART MANUFACTURING SUMMIT｣"),_c('br'),_vm._v(" 開催中")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"closeImgBox01"},[_c('img',{attrs:{"src":require("@/assets/img/route1/1-9/image01.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"closeLinkBox01"},[_c('a',{attrs:{"href":"https://sms-gi.com/","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/img/route1/1-9/link01.svg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"closeText01"},[_vm._v("｢MOOX RIDE Navi｣へのご参加、"),_c('br'),_vm._v(" ありがとうございました。"),_c('br'),_vm._v(" 展示会での有意義な"),_c('br'),_vm._v(" ビジネスをお祈りしています。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header"},[_c('h1',[_c('a',{attrs:{"href":""}},[_c('img',{attrs:{"src":require("@/assets/img/common/logo.svg"),"alt":"MOOX"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"closeText01"},[_vm._v("At Aichi Sky Expo,"),_c('br'),_vm._v(" March 13 (Wed) - 15 (Fri), 2024"),_c('br'),_vm._v(" \"SMART MANUFACTURING SUMMIT\""),_c('br'),_vm._v(" being held at Aichi Sky Expo")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"closeImgBox01"},[_c('img',{attrs:{"src":require("@/assets/img/route1/1-9/image01.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"closeLinkBox01"},[_c('a',{attrs:{"href":"https://sms-gi.com/","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/img/route1/1-9/link01.svg"),"alt":""}})])])
}]

export { render, staticRenderFns }