<template>
    <div class="wrapper">
        <div>
            <h2>座席情報</h2>
        </div>
        <div>
            <h3>座席番号</h3>
        </div>
        <div>
            ??
        </div>
        <div>
            <h3>行き先</h3>
        </div>
        <div>
            <h3>Aichi Sky Expo</h3>
        </div>
        <div>
            国籍:{{ user.nationality }}<br>
            年代:{{ user.age }}<br>
            職業:{{ user.job }}<br>
        </div>
</div><!-- wrapper -->
</template>
  
<style></style>
  
<script>

export default {
    name: "SeatInfomation",
    components: {

    },
    props: ['user'],
    data() {
        return {
        };
    },
    async mounted() {
        console.log("moutend SeatInfomation"); 
        console.log('open sample page');
    },
    methods: {

    }
};
</script>