<template>
	<div>
		<div class="contents_guide" v-if="language === 'Japanese'">

			<header class="header">
				<h1><a href=""><img src="@/assets/img/common/logo.svg" alt="MOOX"></a></h1>
			</header>

			<div class="container_top02">

				<section class="section01">
					<div class="mainTitle"><img src="@/assets/img/route1/1-1/main_logo.svg" alt="MOOX RIDE Navi" /></div>
					<p class="thanksText01">ご乗車ありがとうございます。</p>
					<p class="thanksText02 mt-30">このバスは<br>フライト・オブ・ドリームズ経由<br>第1ターミナル 行きです。</p>
				</section>

			</div>

		</div>
		<div class="contents_guide" v-else>

			<header class="header">
				<h1><a href=""><img src="@/assets/img/common/logo.svg" alt="MOOX"></a></h1>
			</header>

			<div class="container_top02">

				<section class="section01">
					<div class="mainTitle"><img src="@/assets/img/route1/1-1/main_logo.svg" alt="MOOX RIDE Navi" /></div>
					<p class="thanksText01">Thank you for your ride.</p>
					<p class="thanksText02 mt-30">This bus goes to Terminal 1 via FLIGHT OF DREAMS Terminal 1.</p>
				</section>

			</div>

		</div>
	</div>
</template>
<style scoped></style>
<script>
import MooxApi from '../../MooxApi';

export default {
	name: "Route2_4",
	components: {

	},
	data() {
		return {
			seatId: null,
			vehicleId: null,
			token: null,
			destination: "",
			seat: "",
			language: "Japanese",
		};
	},
	async mounted() {
		console.log("moutend Route2_4");
		MooxApi.StartUpdate();
		console.log(MooxApi.GetRoute());
		this.destination = MooxApi.GetRoute() ? MooxApi.GetRoute().destinationName : "行き先なし";
		this.seat = MooxApi.GetSeatName();
		const profile = await MooxApi.GetProfileFromLocal();
		// 更新した時にAppより先にここが呼ばれるので対処療法的に国だけ取得する
		if (!profile.nationality) {
			profile.nationality = this.$cookies.get('nationality');
		}
		if (profile.nationality === 'Japan') {
			this.language = 'Japanese';
			document.getElementsByTagName('html')[0].lang = 'ja'
		}
		else {
			this.language = 'English';
			document.getElementsByTagName('html')[0].lang = 'en'
		}
	},
	methods: {
		getProfileTest: async function () {
			const profile = await MooxApi.GetProfileFromApi();
			alert("GET " + JSON.stringify(profile));
			console.log(profile);
		}
	}
};
</script>