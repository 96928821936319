<template>
    <div class="wrapper">
        <div>
            投票テスト(themeId: {{ themeId }})
        </div>
        <div>
            <button @click="vote(1)">1を選択</button><br>
            <button @click="vote(2)">2を選択</button><br>
            <button @click="vote(3)">3を選択</button><br>
            <button @click="vote(4)">4を選択</button><br>
            <br>
            <div v-if="choice === 0">選択していません</div>
            <div v-if="choice !== 0">No.{{choice}}を選択しました。</div>
        </div>
        <div>投票状態</div>
        <div v-show="isOpenChoiceShort">
            投票受付中
        </div>
        <div v-show="!isOpenChoiceShort">
            投票終了
        </div>
        <div>{{ isOpenChoiceShort }}</div>
    </div><!-- wrapper -->
</template>
  
<style scoped>
@import "../css/style.css";
</style>
  
<script>
import Vue from 'vue'
import MooxApi from '../MooxApi';
import VueCookies from 'vue-cookies'

Vue.use(VueCookies, { expires: '60 * 60 * 6'})

export default {
    name: "Choice",
    props:['isOpenChoiceShort'],
    components: {

    },
    data() {
        return {
            choice: 0,
            themeId: "c01ed5e0-e17b-4c03-b739-08dbd5de717b",
            seatId: null,
            vehicleId: null,
            token: null,
        };
    },
    async mounted() {
        console.log("moutend Choice"); 
        if(this.$cookies.get('choice')){
            this.choice = parseInt(this.$cookies.get('choice'));
        }
       this.seatId = this.$route.props
       MooxApi.StartUpdate();
    },
    methods: {
        vote: async function (ch) {
            if(this.choice !== 0){
                alert("投票済みです。");
                return;
            }
            const result = await MooxApi.PostChoice(this.themeId, ch);
            if(result === false){
                alert("投票に失敗しました。");
            }
            else if(result === true){
                this.choice = ch;
                this.$cookies.set('choice',ch, MooxApi.cookieExpire);
            }
        }
    }
};
</script>