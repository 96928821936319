var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.language === 'Japanese')?_c('div',{staticClass:"contents_fod"},[_vm._m(0),_vm._m(1),_vm._m(2)]):_c('div',{staticClass:"contents_fod"},[_vm._m(3),_vm._m(4),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header"},[_c('h1',[_c('a',{attrs:{"href":""}},[_c('img',{attrs:{"src":require("@/assets/img/common/logo.svg"),"alt":"MOOX"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fod_logo"},[_c('img',{attrs:{"src":require("@/assets/img/route2/2-5/fod_logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container_fod"},[_c('section',{staticClass:"section_spot01"},[_c('div',{staticClass:"fodTitle"},[_c('a',{attrs:{"href":"https://www.centrair.jp/service/flightofdreams.html","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/img/route2/2-5/fod_title_jp.svg"),"alt":""}})])]),_c('div',{staticClass:"fodInner"},[_c('p',{staticClass:"fodText01"},[_vm._v("フライト・オブ・ドリームズは、ボーイング787初号機の展示をメインとした複合商業施設です。")]),_c('div',{staticClass:"fodImg01"},[_c('a',{attrs:{"href":"https://www.centrair.jp/event/enjoy/flightpark/index.html","target":"_blank"}})]),_c('p',{staticClass:"fodText02"},[_vm._v("フライト・オブ・ドリームズ1階の「フライトパーク」では、ボーイング787初号機（ZA001）のスケールの大きさと迫力を間近で体感できます。")]),_c('div',{staticClass:"fodImg02"}),_c('p',{staticClass:"fodText02"},[_vm._v("ボーイング創業の街、シアトルをテーマとしたエリアです。飛行機の間近でお食事をお楽しみいただけます。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header"},[_c('h1',[_c('a',{attrs:{"href":""}},[_c('img',{attrs:{"src":require("@/assets/img/common/logo.svg"),"alt":"MOOX"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fod_logo"},[_c('img',{attrs:{"src":require("@/assets/img/route2/2-5/fod_logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container_fod"},[_c('section',{staticClass:"section_spot01"},[_c('div',{staticClass:"fodTitle"},[_c('a',{attrs:{"href":"https://www.centrair.jp/service/flightofdreams.html","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/img/route2/2-5/fod_title_en.svg"),"alt":""}})])]),_c('div',{staticClass:"fodInner"},[_c('p',{staticClass:"fodText01"},[_vm._v("FLIGHT OF DREAMS is a commercial complex that mainly displays the first Boeing 787 aircraft.")]),_c('div',{staticClass:"fodImg01_en"},[_c('a',{attrs:{"href":"https://www.centrair.jp/event/enjoy/flightpark/index.html","target":"_blank"}})]),_c('p',{staticClass:"fodText02"},[_vm._v("At the \"Flight Park\" on the first floor of FLIGHT OF DREAMS, visitors can experience the scale and power of the first Boeing 787 (ZA001) up close.")]),_c('div',{staticClass:"fodImg02_en"}),_c('p',{staticClass:"fodText02"},[_vm._v("This area is themed around Seattle, the city where Boeing was founded.Diners can enjoy their meals up close to airplanes.")])])])])
}]

export { render, staticRenderFns }