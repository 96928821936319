<template>
	<div>
		<div class="contents_close" v-if="language === 'Japanese'">

			<header class="header">
				<h1><a href=""><img src="@/assets/img/common/logo.svg" alt="MOOX"></a></h1>
			</header>

			<div class="container_top02">

				<section class="section01">
					<p class="closeText01">Aichi Sky Expoでは、<br>
						2024年3月13日(水)～15日(金)<br>
						「SMART MANUFACTURING SUMMIT｣<br>
						開催中</p>
					<div class="closeImgBox01"><img src="@/assets/img/route1/1-9/image01.png" alt="" /></div>
					<div class="closeLinkBox01"><a href="https://sms-gi.com/" target="_blank"><img src="@/assets/img/route1/1-9/link01.svg" alt=""/></a></div>
					<p class="closeText01">｢MOOX RIDE Navi｣へのご参加、<br>
						ありがとうございました。<br>
						展示会での有意義な<br>
						ビジネスをお祈りしています。</p>
					<button class="closeLinkBox02" @click="onClickEnquete"><img src="@/assets/img/route1/1-9/link02.svg"
							alt="" /></button>
				</section>

			</div>
		</div>
		<div class="contents_close" v-else>

			<header class="header">
				<h1><a href=""><img src="@/assets/img/common/logo.svg" alt="MOOX"></a></h1>
			</header>

			<div class="container_top02">

				<section class="section01">
					<p class="closeText01">At Aichi Sky Expo,<br>
						March 13 (Wed) - 15 (Fri), 2024<br>
						"SMART MANUFACTURING SUMMIT"<br>
						being held at Aichi Sky Expo</p>
					<div class="closeImgBox01"><img src="@/assets/img/route1/1-9/image01.png" alt="" /></div>
					<div class="closeLinkBox01"><a href="https://sms-gi.com/" target="_blank"><img src="@/assets/img/route1/1-9/link01.svg" alt=""/></a></div>
					<p class="closeText01">Thank you for participating on the “MOOX RIDE Navi”.We wish you find great
						business opportunities at the exhibition.</p>
					<button class="closeLinkBox02" @click="onClickEnquete"><img
							src="@/assets/img/route1/1-9/link02_en.svg" alt="" /></button>
				</section>

			</div>
		</div>

	</div>
</template>

<style scoped></style>

<script>
import MooxApi from '../../MooxApi';
import Attribute from "@/assets/attribute.json";

export default {
	name: "Route1_9",
	components: {

	},
	data() {
		return {
			seatId: null,
			vehicleId: null,
			token: null,
			destination: "",
			seat: "",
			language: "Japanese",
		};
	},
	async mounted() {
		console.log("moutend Route1_9");
		MooxApi.StartUpdate();
		console.log(MooxApi.GetRoute());
		this.destination = MooxApi.GetRoute() ? MooxApi.GetRoute().destinationName : "行き先なし";
		this.seat = MooxApi.GetSeatName();
		const profile = await MooxApi.GetProfileFromLocal();
		// 更新した時にAppより先にここが呼ばれるので対処療法的に国だけ取得する
		if (!profile.nationality) {
			profile.nationality = this.$cookies.get('nationality');
		}
		if (profile.nationality === 'Japan') {
			this.language = 'Japanese';
			document.getElementsByTagName('html')[0].lang = 'ja'
		}
		else {
			this.language = 'English';
			document.getElementsByTagName('html')[0].lang = 'en'
		}
	},
	methods: {
		onClickEnquete: function () {
			MooxApi.RedirectToEnquite(Attribute);
		}
	}
};
</script>
