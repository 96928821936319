<template>
	<div>
		<div class="contents_fod" v-if="language === 'Japanese'">

			<header class="header">
				<h1><a href=""><img src="@/assets/img/common/logo.svg" alt="MOOX"></a></h1>
			</header>

			<div class="container_close">

				<section class="section_close02">

					<div class="thanksInner">
						<p class="close02Text01">｢MOOX-RIDE Navi｣
							へのご参加、ありがとうございました。
							<br>
							<br>
							セントレアにはまだまだたくさんの楽しみ方があります。<br>
							是非お立ち寄りください。
						</p>

						<div class="linkBox">
							<div class="thanksImg01"><img src="@/assets/img/route2/2-8/img01.jpg" alt="" /></div>
						</div>

						<div class="linkBox02">
							<p class="link"><a href="https://www.centrair.jp/event/enjoy/index.html"
									target="_blank"><img src="@/assets/img/route2/2-8/link01_jp.svg"
										alt="セントレアの楽しみ方" /></a></p>
							<p class="link"><a href="https://www.centrair.jp/shop-dine/gift/index.html"
									target="_blank"><img src="@/assets/img/route2/2-8/link02_jp.svg"
										alt="セントレアのおみやげ特集" /></a></p>
							<p class="link"><a href="https://www.centrair.jp/shop-dine/shop/" target="_blank"><img
										src="@/assets/img/route2/2-8/link03_jp.svg" alt="ショップ" /></a></p>
							<p class="link"><a href="https://www.centrair.jp/event/index.html" target="_blank"><img
										src="@/assets/img/route2/2-8/link04_jp.svg" alt="イベント・キャンペーン" /></a></p>
							<p class="link"><a href="#" @click.prevent.stop="onClickEnquete"><img
										src="@/assets/img/route2/2-8/link05_jp.svg" alt="アンケートはこちら" /></a></p>

							<!-- <p class="link"><button class="closeLinkBox02 link" @click="onClickEnquete"><img src="@/assets/img/route2/2-8/link05_jp.svg"
										alt="アンケートはこちら" /></button></p> -->
						</div>

					</div>

				</section>

			</div>

		</div>
		<div class="contents_fod" v-else>

			<header class="header">
				<h1><a href=""><img src="@/assets/img/common/logo.svg" alt="MOOX"></a></h1>
			</header>

			<div class="container_close">

				<section class="section_close02">

					<div class="thanksInner">
						<p class="close02Text01">Thank you for participating on the “MOOX RIDE Navi”.<br>There are many
							more ways to enjoy Centrair.<br>Please visit us again.</p>

						<div class="linkBox">
							<div class="thanksImg01"><img src="@/assets/img/route2/2-8/img01.jpg" alt="" /></div>
						</div>

						<div class="linkBox02">
							<p class="link"><a href="https://www.centrair.jp/event/enjoy/index.html"
									target="_blank"><img src="@/assets/img/route2/2-8/link01_en.svg"
										alt="How to enjoy Centrair" /></a></p>
							<p class="link"><a href="https://www.centrair.jp/shop-dine/gift/index.html"
									target="_blank"><img src="@/assets/img/route2/2-8/link02_en.svg"
										alt="Centrair souvenir special feature" /></a></p>
							<p class="link"><a href="https://www.centrair.jp/shop-dine/shop/" target="_blank"><img
										src="@/assets/img/route2/2-8/link03_en.svg" alt="Shop" /></a></p>
							<p class="link"><a href="https://www.centrair.jp/event/index.html" target="_blank"><img
										src="@/assets/img/route2/2-8/link04_en.svg" alt="Event/Campaign" /></a></p>
							<p class="link"><a href="#" @click.prevent.stop="onClickEnquete"><img
										src="@/assets/img/route2/2-8/link05_en.svg" alt="アンケートはこちら" /></a></p>
							<!-- <p class="link"><button class="closeLinkBox02" @click="onClickEnquete"><img src="@/assets/img/route2/2-8/link05_en.svg"
										alt="アンケートはこちら" /></button></p> -->
						</div>

					</div>

				</section>

			</div>

		</div>
	</div>
</template>
<style scoped></style>
<script>
import MooxApi from '../../MooxApi';
import Attribute from "@/assets/attribute.json";

export default {
	name: "Route2_8",
	components: {

	},
	data() {
		return {
			seatId: null,
			vehicleId: null,
			token: null,
			destination: "",
			seat: "",
			language: "Japanese",
		};
	},
	async mounted() {
		console.log("moutend Route2_8");


		MooxApi.StartUpdate();
		console.log(MooxApi.GetRoute());
		this.destination = MooxApi.GetRoute() ? MooxApi.GetRoute().destinationName : "行き先なし";
		this.seat = MooxApi.GetSeatName();
		const profile = await MooxApi.GetProfileFromLocal();

		// 更新した時にAppより先にここが呼ばれるので対処療法的に国だけ取得する
		if (!profile.nationality) {
			profile.nationality = this.$cookies.get('nationality');
		}
		if (profile.nationality === 'Japan') {
			this.language = 'Japanese';
			document.getElementsByTagName('html')[0].lang = 'ja'
		}
		else {
			this.language = 'English';
			document.getElementsByTagName('html')[0].lang = 'en'
		}
	},
	methods: {
		getProfileTest: async function () {
			const profile = await MooxApi.GetProfileFromApi();
			alert("GET " + JSON.stringify(profile));
			console.log(profile);
		},
		onClickEnquete: async function () {
			MooxApi.RedirectToEnquite(Attribute);
		}
	}
};
</script>