<template>
	<div>
		<div class="contents_cm" v-if="language === 'Japanese'">

			<header class="header">
				<h1><a href=""><img src="@/assets/img/common/logo.svg" alt="MOOX"></a></h1>
			</header>

			<div class="container">

				<section class="section_spot01">
					<p class="spotText01_long">常滑市 おすすめ観光スポット</p>
					<div class="spotInner">

						<div class="spot_longImg01"><a href="https://www.tokoname-kankou.net/spot/detail/9/"
								target="_blank"></a></div>
						<div class="spot_longImg02"><a href="https://www.tokoname-kankou.net/spot/detail/13/"
								target="_blank"></a></div>
						<div class="spot_longImg03"><a href="https://www.tokoname-kankou.net/spot/detail/3/"
								target="_blank"></a></div>

					</div>
				</section>

			</div>

		</div>
		<div class="contents_cm" v-else>

			<header class="header">
				<h1><a href=""><img src="@/assets/img/common/logo.svg" alt="MOOX"></a></h1>
			</header>

			<div class="container">

				<section class="section_spot01">
					<p class="spotText01_long">Tokoname City<br>Recommended sightseeing spot</p>
					<div class="spotInner">

						<div class="spot_longImg01_en"><a href="https://www.tokoname-kankou.net/spot/detail/9/"
								target="_blank"></a></div>
						<div class="spot_longImg02_en"><a href="https://www.tokoname-kankou.net/spot/detail/13/"
								target="_blank"></a></div>
						<div class="spot_longImg03_en"><a href="https://www.tokoname-kankou.net/spot/detail/3/"
								target="_blank"></a></div>

					</div>
				</section>

			</div>
		</div>

	</div>

</template>
<style scoped></style>
<script>
import MooxApi from '../../MooxApi';

export default {
	name: "Route1_6",
	components: {

	},
	data() {
		return {
			seatId: null,
			vehicleId: null,
			token: null,
			destination: "",
			seat: "",
			language: "Japanese",
		};
	},
	async mounted() {
		console.log("moutend Route1_6");
		MooxApi.StartUpdate();
		console.log(MooxApi.GetRoute());
		this.destination = MooxApi.GetRoute() ? MooxApi.GetRoute().destinationName : "行き先なし";
		this.seat = MooxApi.GetSeatName();
		const profile = await MooxApi.GetProfileFromLocal();
		// 更新した時にAppより先にここが呼ばれるので対処療法的に国だけ取得する
		if(!profile.nationality){
			profile.nationality = this.$cookies.get('nationality');
		}
		if(profile.nationality === 'Japan'){
			this.language = 'Japanese';
			document.getElementsByTagName('html')[0].lang = 'ja'
		}
		else{
			this.language = 'English';
			document.getElementsByTagName('html')[0].lang = 'en'
		}
	},
	methods: {
	}
};
</script>